import ApiEasycatService from '@/api/new/services/api.easycat.service'
import ApiService from '@/api/new/services/api.service'
import i18n from '@/i18n'

const state = {
  showBetaEditor: true,
  showEditorPreview: true,
  currentSegment: null,
  currentSegmentId: null,
  translationHistory: [],
  filterInput: null,
  uploadedFile: null,
  segmentStatistics: null,
  failedSegments: null,
  segmentTagMismatch: false,
  repetition: {},
  linkedSegment: null,
  matched: {
    segments: [],
    found: null
  },
  searchPages: {
    current: 1,
    total: 0,
    totalSegments: 0,
    pageSize: 50
  },
  failedPages: {
    current: 1,
    total: 0,
    totalSegments: 0,
    pageSize: 10
  }
}

const mutations = {
  setCurrentSegment(state, segment) {
    state.currentSegment = segment || null
    state.currentSegmentId = segment.id || null
  },
  setTranslationHistory(state, segment) {
    state.translationHistory = segment
  },
  setFilterInput(state, filter) {
    state.filterInput = filter
  },
  setShowBetaEditor(state, show) {
    state.showBetaEditor = show
  },
  setShowEditorPreview(state, show) {
    state.showEditorPreview = show
  },
  setUploadedFile(state, file) {
    state.uploadedFile = file
  },
  setSegmentStatistics(state, statistics) {
    state.segmentStatistics = statistics
  },
  setFailedSegments(state, segments) {
    state.failedSegments = segments
  },
  setFailedSegment(state, { index, segment }) {
    state.failedSegments[index] = segment
  },
  setFailedSegmentTarget(state, { index, target, confirmed }) {
    state.failedSegments[index].attributes.target = target
    state.failedSegments[index].attributes.is_confirmed = confirmed
  },
  setMatchedSegments(state, matched) {
    state.matched.segments = matched.segments
    state.matched.found = matched.found
  },
  setSearchPages(state, pages) {
    state.searchPages.current = pages.current_page
    state.searchPages.total = pages.last_page
    state.searchPages.totalSegments = pages.total
    state.searchPages.pageSize = pages.per_page
  },
  setFailedPages(state, pages) {
    state.failedPages.current = pages.current_page
    state.failedPages.total = pages.last_page
    state.failedPages.totalSegments = pages.total
    state.failedPages.pageSize = pages.per_page
  },
  setSegmentTagMismatch(state, isMismatch) {
    state.segmentTagMismatch = isMismatch
  },
  setRepetition(state, repetition) {
    state.repetition = repetition
  },
  setCurrentLinkedSegment(state, segment) {
    state.linkedSegment = segment
  }
}

const actions = {
  async getTranslationFile(
    { dispatch, commit, getters, state },
    { target, currentPage, perPage, failedPage }
  ) {
    await ApiEasycatService.get(
      `suppliers/files/${getters.fileId}/file-segments/index`,
      {
        params: {
          page: currentPage,
          perPage,
          target_language: target,
          task_type: getters.taskType
        }
      }
    )
      .then(async (res) => {
        const file = res.data.data
        const pages = res.data.meta
        await commit('supplierFile/setFile', file, {
          root: true
        })
        await commit('supplierFile/setFilePages', pages, { root: true })
        if (getters.isAiTask) {
          await dispatch('getUploadedFile', target)
          if (state.showBetaEditor)
            await dispatch('getFailedSegments', {
              target,
              currentPage: failedPage || state.failedPages.current,
              perPage: 10
            })
        }
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getFailedSegments(
    { commit, getters },
    { target, currentPage, perPage }
  ) {
    await ApiEasycatService.get(
      `suppliers/files/${getters.fileId}/file-segments/failed`,
      {
        params: {
          page: currentPage,
          perPage,
          target_language: target,
          task_type: getters.taskType
        }
      }
    )
      .then(async (res) => {
        const segments = res.data.data
        const pages = res.data.meta
        commit('setFailedSegments', segments)
        commit('setFailedPages', pages)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getUploadedFile({ commit, rootGetters, getters }, target) {
    const data = {
      data: {
        type: 'files',
        attributes: {
          target_language: target,
          task_type: getters.taskType,
          supplier: {
            id: rootGetters['supplier/supplier'].id,
            attributes: {
              first_name:
                rootGetters['supplier/supplier'].attributes.first_name,
              last_name: rootGetters['supplier/supplier'].attributes.last_name,
              user_id: rootGetters['supplier/supplier'].attributes.user_id
            }
          }
        }
      }
    }
    await ApiEasycatService.post(
      `suppliers/files/${getters.fileId}/show?target_language=${target}`,
      data
    )
      .then(async (res) => {
        const file = res.data.data
        commit('setUploadedFile', file)
        commit('setSegmentStatistics', file.attributes.segment_statistics)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateAllSegmentsStatus({ dispatch, rootState, getters }, payload) {
    const perPage = rootState['supplierFile'].filePages.pageSize
    const data = {
      data: {
        type: 'file_segments',
        attributes: {
          is_confirmed: payload.status,
          target_language: payload.target,
          task_type: getters.taskType
        }
      }
    }
    await ApiEasycatService.post(
      `suppliers/files/${getters.fileId}/file-segments/bulk-confirm`,
      data
    )
      .then(async () => {
        dispatch(
          'toast/success',
          {
            message: payload.status
              ? i18n.t('customer.toast.success.confirm_segments')
              : i18n.t('shared.toast.success.uncofirm_segments')
          },
          { root: true }
        )
        await dispatch('getTranslationFile', {
          target: payload.target,
          currentPage: rootState['supplierFile'].filePages.current,
          perPage
        })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateSegment(
    { state, commit, getters, dispatch, rootState },
    payload
  ) {
    await commit('task/setIsReviewSaved', false, { root: true })
    await commit('task/setSavingSegment', true, { root: true })
    const target =
      rootState['supplierDashboard'].currentTask.attributes.target_language
    const currentPage = rootState['supplierFile'].filePages.current
    const perPage = rootState['supplierFile'].filePages.pageSize
    const data = {
      data: {
        type: 'file_segments',
        attributes: {
          ...payload.attributes,
          task_type: getters.taskType
        }
      }
    }
    await ApiEasycatService.put(
      `suppliers/files/${getters.fileId}/file-segments/${payload.id}/update`,
      data
    )
      .then(async (response) => {
        if (!payload.propagate) {
          if (payload.getFile) {
            if (state.filterInput) {
              await dispatch('filterAiFileSegments', {
                ...state.filterInput,
                currentPage
              })
              await commit('task/setSavingSegment', false, { root: true })
            } else {
              await dispatch('getTranslationFile', {
                target,
                currentPage,
                perPage,
                failedPage: payload.nextPage
              })
              await commit('task/setSavingSegment', false, { root: true })
            }
          } else {
            dispatch('applySegmentUpdate', response)
          }

          if (getters.isAiTask && payload.nextPage !== undefined) {
            await dispatch('getUploadedFile', target)
            if (state.showBetaEditor)
              await dispatch('getFailedSegments', {
                target,
                currentPage: payload.nextPage || state.failedPages.current,
                perPage: 10
              })
          }

          await dispatch('getFileSegmentHistory', payload.id)
          await commit('task/setIsReviewSaved', true, { root: true })
          await commit('task/setSavingSegment', false, { root: true })
        }
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateRepetitions({ state, dispatch, getters, rootState }, payload) {
    const target =
      rootState['supplierDashboard'].currentTask.attributes.target_language
    const data = {
      data: {
        type: 'file_segments',
        attributes: {
          ...payload.attributes
        }
      }
    }
    await ApiEasycatService.put(
      `suppliers/files/${getters.fileId}/file-segments/repetitions-propagate`,
      data
    )
      .then(async () => {
        if (state.filterInput)
          await dispatch('filterAiFileSegments', {
            ...state.filterInput,
            currentPage: rootState['supplierFile'].filePages.current
          })
        else
          await dispatch('getTranslationFile', {
            target: target,
            currentPage: rootState['supplierFile'].filePages.current,
            perPage: rootState['supplierFile'].filePages.pageSize
          })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async filterAiFileSegments(
    { dispatch, commit, getters, rootState },
    payload
  ) {
    const perPage = rootState['supplierFile'].filePages.pageSize
    const data = {
      data: {
        type: 'files',
        attributes: {
          ...payload,
          task_type: getters.taskType
        }
      }
    }
    if (payload.value.length === 0) {
      await commit('setFilterInput', null)
      return await dispatch('getTranslationFile', {
        target: payload.target_language,
        currentPage: rootState['supplierFile'].filePages.current,
        perPage
      })
    }
    await ApiEasycatService.post(
      `suppliers/files/${getters.fileId}/file-segments/search?perPage=${perPage}&page=${payload.currentPage || 1}`,
      data
    )
      .then(async (res) => {
        const file = res.data.data
        const pages = res.data.meta
        await commit('supplierFile/setFile', file, {
          root: true
        })
        await commit('supplierFile/setFilePages', pages, { root: true })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async searchAndReplace({ dispatch, getters, rootState }, payload) {
    const target =
      rootState['supplierDashboard'].currentTask.attributes.target_language
    const source =
      rootState['supplierDashboard'].currentTask.attributes.source_language
    const data = {
      data: {
        type: 'files',
        attributes: {
          target_language: target,
          search_value: payload.search,
          replace_value: payload.replace,
          segment_ids: payload.segmentIds
        }
      }
    }
    await ApiEasycatService.post(
      `suppliers/files/${getters.fileId}/supplier-replace-all`,
      data
    )
      .then(async () => {
        await dispatch('filterAiFileSegments', {
          source_language: source,
          target_language: target,
          value: payload.search,
          type: 'target'
        })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getFuzzySegments({ commit, getters }, id) {
    await ApiEasycatService.get(
      `suppliers/files/${getters.fileId}/file-segments/${id}/fuzzy`
    )
      .then(async (res) => {
        const segments = res.data.data
        const matchedFound = segments.length > 0 ? true : false
        const obj = {
          found: matchedFound,
          segments
        }
        commit('setMatchedSegments', obj)
        commit('setSearchPages', {
          current_page: 1,
          last_page: 0,
          total: 0,
          per_page: 50
        })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getStateFileSegmentHistory({ state, dispatch }, id) {
    if (
      state.translationHistory.length === 0 ||
      state.translationHistory[0]?.attributes?.segment_id !== id
    )
      await dispatch('getFileSegmentHistory', id)
  },
  async getFileSegmentHistory({ commit, getters }, segmentId) {
    await ApiEasycatService.get(
      `suppliers/files/${getters.fileId}/file-segments/${segmentId}/history`
    )
      .then(async (res) => {
        const history = res.data.data
        await commit('setTranslationHistory', history)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async searchTm({ commit }, payload) {
    const data = {
      data: {
        type: 'translation_memories',
        attributes: {
          source_language: payload.source,
          target_language: payload.target,
          value: payload.value,
          type: payload.type
        }
      }
    }
    await ApiEasycatService.post(
      `suppliers/teams/${payload.teamName}/translation-memories/search?page=${payload.currentPage}`,
      data
    )
      .then(async (res) => {
        const segments = res.data.data
        const pages = res.data.meta
        const matchedFound = segments.length > 0 ? true : false
        const obj = {
          found: matchedFound,
          segments
        }
        commit('setMatchedSegments', obj)
        commit('setSearchPages', pages)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async searchTb({ commit }, payload) {
    const data = {
      data: {
        type: 'term_banks',
        attributes: {
          source_language: payload.source,
          target_language: payload.target,
          value: payload.value,
          type: payload.type
        }
      }
    }
    await ApiEasycatService.post(
      `suppliers/teams/${payload.teamName}/term-banks/search?page=${payload.currentPage}`,
      data
    )
      .then(async (res) => {
        const segments = res.data.data
        const pages = res.data.meta
        const matchedFound = segments.length > 0 ? true : false
        const obj = {
          found: matchedFound,
          segments
        }
        commit('setMatchedSegments', obj)
        commit('setSearchPages', pages)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async acceptTranslation({ getters }, payload) {
    const data = {
      data: {
        type: 'files',
        attributes: {
          ...payload
        }
      }
    }
    await ApiEasycatService.post(
      `suppliers/files/${getters.fileId}/supplier-accept-translation`,
      data
    ).catch((err) => {
      throw new Error(JSON.stringify(err))
    })
  },
  async declineTranslation({ getters }, payload) {
    const data = {
      data: {
        type: 'files',
        attributes: {
          ...payload
        }
      }
    }
    await ApiEasycatService.post(
      `suppliers/files/${getters.fileId}/supplier-decline-translation`,
      data
    ).catch((err) => {
      throw new Error(JSON.stringify(err))
    })
  },
  async finishTranslation({ getters, rootState, rootGetters }) {
    await ApiService.post(
      `suppliers/${rootGetters['supplier/supplier'].id}/tasks/${rootState['supplierDashboard'].currentTask.id}/complete-translation?taskType=${getters.taskType}`
    ).catch((err) => {
      throw new Error(JSON.stringify(err))
    })
  },

  applySegmentUpdate(
    { state, dispatch, commit, getters, rootGetters, rootState },
    payload
  ) {
    // check if updated segment has repetitions
    const hasRepetitions =
      payload.data.data.attributes.linked_repetitions?.length > 0 || false
    const shouldPropagate =
      payload.data.data.attributes?.repetition?.propagate || false

    if (state.showBetaEditor && getters.isAiTask) {
      // find the index of the updated segment in the file segments
      const index = state.failedSegments.findIndex(
        (seg) => seg.id === payload.data.data.id
      )
      // if found update the segment
      if (index !== -1) {
        commit('setFailedSegment', { index, segment: payload.data.data })
      }
    } else {
      // find the index of the updated segment in the file segments
      const index = rootState['supplierFile'].file.findIndex(
        (seg) => seg.id === payload.data.data.id
      )
      // if found update the segment
      if (index !== -1) {
        commit(
          'supplierFile/setRevisionFileSegment',
          { index, segment: payload.data.data },
          { root: true }
        )
      }
    }

    // if the updated segment has repetitions, update them as well
    if (hasRepetitions && shouldPropagate) {
      const segmentRepetitions = payload.data.data.attributes.linked_repetitions
      const isConfirmed = payload.data.data.attributes.is_confirmed

      // when beta editor, update failed segment
      if (state.showBetaEditor && getters.isAiTask) {
        segmentRepetitions.forEach((repetition) => {
          const failedIndex = state.failedSegments.findIndex(
            (failedSegment) => failedSegment.id === repetition['_id']
          )

          if (failedIndex !== -1) {
            const allowPropagation =
              state.failedSegments[failedIndex].attributes.repetition.propagate

            if (allowPropagation) {
              commit('setFailedSegmentTarget', {
                index: failedIndex,
                target: repetition.target,
                confirmed: isConfirmed
              })
            }
          }
        })
      }
      // otherwise update file segment
      else {
        segmentRepetitions.forEach((repetition) => {
          const segmentIndex = rootState['supplierFile'].file.findIndex(
            (fileSegment) => fileSegment.id === repetition['_id']
          )

          if (segmentIndex !== -1) {
            const allowPropagation =
              rootState['supplierFile'].file[segmentIndex].attributes.repetition
                .propagate

            if (allowPropagation) {
              commit(
                'supplierFile/setRevisionFileSegmentTarget',
                {
                  index: segmentIndex,
                  target: repetition.target,
                  confirmed: isConfirmed
                },
                { root: true }
              )
            }
          }
        })
      }
    }
  }
}

const getters = {
  isAiTask: (state, getters, rootState, rootGetters) => {
    return (
      rootState['supplierDashboard'].currentTask.attributes.type ===
      'ai_translation'
    )
  },
  isSupplierTranslation: (state, getters, rootState, rootGetters) => {
    const taskType = rootState.supplierDashboard.currentTask.attributes.type
    return (
      taskType === 'ai_translation' ||
      taskType === 'translation' ||
      taskType === 'review' ||
      taskType === 'qa'
    )
  },
  taskType: (state, getters, rootState, rootGetters) => {
    switch (rootState['supplierDashboard'].currentTask.attributes.type) {
      case 'translation':
        return 'supplier_translation'
      case 'review':
        return 'supplier_review'
      case 'ai_translation':
        return rootState['supplierDashboard'].currentTask.attributes.status ===
          'COMPLETED'
          ? 'internal_review'
          : 'translation'
      case 'qa':
        return 'qa'
      default:
        'supplier_translation'
    }
  },
  fileId: (state, getters, rootState, rootGetters) => {
    return rootState['supplierDashboard'].currentTask.attributes.cattool_id
  },
  translationStatus: (state, getters, rootState, rootGetters) => {
    const target =
      rootState['supplierDashboard'].currentTask.attributes.target_language
    return state.uploadedFile.attributes.target_language_properties[target]
      .steps.translation.status
  },
  currentPageFailedSegments: (state, getters, rootState, rootGetters) => {
    const threshold = state.uploadedFile.attributes.pipeline.qa.threshold
    const failedSegments = rootState['supplierFile'].file.filter(
      (s) => s.attributes.qa && s.attributes.qa.average <= threshold
    )
    return failedSegments
  },
  fileType: (state) => {
    return state.uploadedFile?.attributes?.type
  }
}

export const translationFile = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
